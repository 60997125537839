import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from '../../configureStore'
import { getPendingOrderAxios } from '../../redux/orderSlice'
import { RestaurantType } from '../../redux/restaurantSlice'
import { api } from '../../utils/api'

import "react-datepicker/dist/react-datepicker.css";

import { MabaliColors } from '../../utils/Colors'


import MinuteSelection from './MinuteSelection'
import DriverSelection, { DriverType } from './DriverSelection'
import OrderOptions from './OrderOptions'
import ApproveModal from '../ApproveModal'
import RejectModal from '../RejectModal'
import { toMoney } from '../../currency'
import { OrdersContext } from '../../providers/OrdersProvider'

moment.locale('iw-IL')
moment.lang('iw-IL')

let audio = new Audio("/sounds/incoming_notification.mp3")

let soundTimeout: any = null


const NewOrders = () => {
  const history = useHistory()
  const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
  const [SelectedOrderIndex, setSelectedOrderIndex] = useState<number>(0);
  const [RejectModalFlag, setRejectModal] = useState(false)
  const [PreparationMinute, setPreparationMinute] = useState<number>(0);
  const [SelectedDriverType, setSelectedDriverType] = useState<DriverType>(DriverType.business);
  const newestPendingOrders = useSelector(
    (state: RootState) => state.order.newestPendingOrders
  );
  const orderContext = useContext(OrdersContext);
  const fetchingPending: boolean = useSelector(
    (state: RootState) => state.order.fetchingPending
  );

  const getPendingOrder = () => {
    if (restaurant) (getPendingOrderAxios({ restaurant: restaurant.id }).then((orders: any[]) => {
      console.log('orders', orders.length)
      // orders.length == 0 && history.replace("/orders")
    }).catch((e) => {
      console.log('Error getPendingOrdr', e);
    }))
    soundTimeout = setTimeout(() => {
      getPendingOrder()
    }, 5000)
  }
  useEffect(() => {
    console.log("fetchingPending", fetchingPending, "newestPendingOrder", newestPendingOrders);
    getPendingOrder()

  }, [restaurant.id, newestPendingOrders.length])


  const Order = newestPendingOrders[SelectedOrderIndex]

  const cancelOrder = (message: string) => {
    console.log("Order cancel", message)
    api.put("/orders/" + Order.id + "/reject", { rejected_message: message })
      .then(({ data }) => {
        console.log("orderCanceled", data)
        // setOrder(data)
        //setRejectModal(false)
        history.replace("/orders")
      })
      .catch((e) => {
        console.warn("canceled order error", e)
      })
  }

  const onApprove = (data: any) => {
    console.log("orderAccepted", data)
    orderContext.fetchOrdersCount(restaurant)
    orderContext.fetchPendingOrders(restaurant)
    //setOrder(data)
    getPendingOrder();
    history.replace("/orders")
  }

  if (newestPendingOrders.length == 0) {
    clearTimeout(soundTimeout)
    audio.pause()
    return null
  }

  if (newestPendingOrders?.length === 0) {
    return <div style={{
      display: 'flex',
      flex: 1
    }}>
      <h1>אין הזמנות חדשות!</h1>
    </div>
  }


  const orderTextStyle = {
    fontSize: 16,
    fontFamily: 'SemiBold'
  }


  const disableApproveOrder = !!!PreparationMinute


  return (
    <div className="container" style={{
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <div style={{
        display: 'flex',
        flex: 1,
        maxWidth: 700,
        // "-webkit-box-shadow": '5px 5px 15px 5px rgba(0,0,0,0.38)',
        boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.38)',
        padding: 20,
        minHeight: 400,
        borderRadius: 6,
        // width : "80%",
        backgroundColor: MabaliColors.white
      }}>
        <div style={{
          // flex: 1,
          width: 270,
          marginRight: 60
        }}>
          <div style={{
            backgroundColor: MabaliColors.black,
            padding: 8,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
            <span style={{
              fontFamily: 'Bold',
              color: MabaliColors.white
            }}>#{Order.id} מספר הזמנה</span>
            <span style={{
              color: MabaliColors.white
            }}>ממתין לאישור שליח</span>
          </div>
          <div>
            <MinuteSelection onPress={(minute: number) => {
              console.log('MinuteSelection', minute)
              setPreparationMinute(minute);
            }} />
            <DriverSelection onPress={(driverType: DriverType) => {
              console.log('DriverSelection', driverType)
              setSelectedDriverType(driverType);
            }} />
            <OrderOptions order={Order} onCancel={() => {

              // modalView.toggle()
              console.log('onCancel')
              setRejectModal(true)

            }} />
            <div>
              <button
                disabled={disableApproveOrder}
                data-bs-toggle="modal"
                data-bs-target="#approveModal"
                style={{
                  width: '100%',
                  height: 65,
                  backgroundColor: MabaliColors.lightBlue,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 5
                }}>
                <span style={{
                  fontFamily: 'Bold',
                  fontSize: 20
                }}>אישור</span>
              </button>
            </div>
          </div>
        </div>
        <div style={{
          flex: 1,
          backgroundColor: MabaliColors.lightGray,
          overflowY: 'scroll',
          flexDirection: 'column',
          direction: 'rtl',
          padding: 14,
          height: 414,
          display: 'flex'
        }}>
          <span style={{
            fontFamily: 'Bold',
            fontSize: 24
          }}>{Order.users_permissions_user.firstName ?? ''} {Order.users_permissions_user.lastName ?? ''}</span>

          <span style={orderTextStyle}>
            {Order.deliveryAddressText}
          </span>
          <span style={orderTextStyle}>
            {Order.phone}
          </span>
          <span style={orderTextStyle}>
            {/*@ts-ignore */}
            {('סה״כ: {0} ₪').format(Order.total)}
          </span>
          <div>
            {Order.cartObj?.restaurant[Order.restaurant.id].map((food) => {
              return <div key={food.id} style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <span style={{
                  fontFamily: 'Bold',
                  fontSize: 24
                }}>{food.menu_category.name}</span>
                <span style={{
                  fontFamily: 'Bold',
                  fontSize: 20
                }}>{food.name_he}</span>
                <span style={{
                  fontFamily: 'Bold',
                  fontSize: 16
                }}>{food.description_he}</span>
                <span style={{
                  fontFamily: 'SemiBold',
                  fontSize: 16
                }}>{food.Customized?.Extras.map((extra) => extra.name_he).join(',')}</span>
                <span style={{
                  fontSize: 20,
                  color: MabaliColors.pink,
                  fontFamily: 'Bold'
                }}>
                  {toMoney(food.price)}
                </span>

                {food.Customized?.Options.map((option) => {
                  return <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <span style={{
                      fontSize: 20,
                      fontFamily: 'Bold'
                    }}>{option.menu_optional.name_he}</span>
                    <span>{option.name_he}</span>
                  </div>
                })}
              </div>
            })}
          </div>
        </div>
      </div>
      <ApproveModal {...{ Order, onApprove, PreparationMinute, SelectedDriverType }} />
      <RejectModal
        isActive={RejectModalFlag}
        toggle={() => setRejectModal((prev) => !prev)}
        onReject={(message) => cancelOrder(message)}
        {...{ Order, onApprove }}
      ></RejectModal>
    </div >
  )
}

export default NewOrders
